try {
  window.$ = window.jQuery;

  $(window).on('load', function () {
    console.log('load');
    require('./parts/SearchBox');
  });

} catch (e) {
  console.error(e);
}
