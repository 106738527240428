$(function () {
  const $header = $('#top');
  const $seachOpenerBtn = $('.md-search-btn');
  const $btnSearch = $('[data-toggle="search-box"]');
  const $searchContainer = $header.find('.uq-search--container');
  const $form = $('.md-search-form');
  const $buttonClose = $('#navbar-custom-toggler');

  $btnSearch.removeAttr('onclick');

  function closeSearchBox() {
    $searchContainer.removeClass('open');
    $form.removeClass('open');
  }

  function searchBox() {
    $seachOpenerBtn.on('click', function (e) {
      if (!$form.hasClass('open')) {
        $searchContainer.addClass('open');
        $form.toggleClass('open');

        if ($form.hasClass('open')) {
          $form.find('input').focus();
        }
      } else {
        closeSearchBox();
      }
    });
  }

  $buttonClose.on('click', function () {
    closeSearchBox();
  });

  searchBox();
});
